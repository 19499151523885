// @ts-nocheck fixme type annotations
import Cookies from "js-cookie";

(() => {
    const HELP_CENTER_COOKIE_NAME = "help_center_widget_open";
    const helpCenterWidgetContainer = document.getElementById("help-center-widget");
    const helpCenteriFrame = helpCenterWidgetContainer.querySelector("iframe");
    const closeButton = document.getElementById("help-center-widget__close_btn");
    const openHelpCenterButtons = document.querySelectorAll("[data-test^=button-help-center]");

    const helpCenterWidget = {
        open: function () {
            helpCenterWidgetContainer?.classList.remove("hidden");
            // small delay to allow css transition to work
            setTimeout(() => {
                helpCenterWidgetContainer?.classList.add("enter");
            }, 10);
            // close if chat is enabled and chat widget is open
            if (typeof CedarChat !== "undefined" && typeof CedarChat.close === "function") {
                CedarChat.close();
            }
            // The cookie is set to remember the state of the widget as "open"
            Cookies.set(HELP_CENTER_COOKIE_NAME, helpCenteriFrame?.getAttribute("src"));
        },
        close: function () {
            helpCenterWidgetContainer?.classList.remove("enter");
            setTimeout(() => {
                Cookies.remove(HELP_CENTER_COOKIE_NAME);
                helpCenterWidgetContainer?.classList.add("hidden");
            }, 300);
        },
    };

    closeButton.addEventListener("click", function (e) {
        e.preventDefault();
        helpCenterWidget.close();
    });
    openHelpCenterButtons.forEach(function (button) {
        button.addEventListener("click", function (e) {
            e.preventDefault();
            helpCenterWidget.open();
        });
    });
    // save current help center page to cookies
    $(helpCenteriFrame).on("load", function () {
        if (Cookies.get(HELP_CENTER_COOKIE_NAME)) {
            const doc = helpCenteriFrame.contentWindow.document;
            Cookies.set(HELP_CENTER_COOKIE_NAME, doc.location.pathname);
        }
    });
    // open help center widget if it was open before
    if (Cookies.get(HELP_CENTER_COOKIE_NAME)) {
        helpCenteriFrame?.setAttribute("src", Cookies.get(HELP_CENTER_COOKIE_NAME));
        helpCenterWidget.open();
    }
    window.helpCenterWidget = helpCenterWidget;
})();

export {};
